.header {
    background-color: $color_main;
    transition: $trsn background-color;
    position: relative;
    padding: 0 60px;
    z-index: 3;
    @include m-tablet-wide() {
        padding: 0 15px;
    }
    &__row {
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        @include m-phone() {
            align-items: center;
            height: auto;
            padding: 10px 0;
        }
    }
    &__logo {
        padding: 10px 0;
        max-width: 130px;
        margin-right: 20px;

        @include m-phone() {
            max-width: 90px;
        }
    }
    &__offices {
        height: 100%;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @include m-phone() {
            display: none;
        }
        &:hover {
            &::before {
                background-color: $color_white;
            }
            .header {
                &__offices-arrow {
                    transform: rotate(180deg);
                }
            }
            .header {
                &__dropdown {
                    display: block;
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 5px;
            background-color: transparent;
            transition: $trsn background-color;
        }
    }

    &__offices-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 10px;
        width: 10px;
        height: 13px;
        margin-right: 10px;
    }

    &__offices-text {
        font-size: 14px;
        line-height: 17px;
        color: $color_white;
    }

    &__offices-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 11px;
        width: 11px;
        height: 5px;
        margin-left: 10px;
        stroke: $color_white;
        stroke-width: 2px;
        transition: $trsn transform;
    }

    &__dropdown {
        display: none;
        padding: 20px 40px 30px 20px;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $color_white;
        @include m-tablet() {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__dropdown-menu {
        display: flex;
        margin-bottom: 30px;
    }

    &__dropdown-item {
        padding: 12px 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        background: #e9eaec;
        margin-right: 10px;
        transition: $trsn;
        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            background-color: $color_main;
            color: $color_white;
            @include m-tablet() {
                background: #e9eaec;
                color: $color_black;
            }
        }
        &.active {
            background-color: $color_main;
            color: $color_white;
        }
    }

    &__dropdown-tables {
    }

    &__dropdown-table {
        display: none;
        &.active {
            display: block;
        }
        table {
            border-collapse: separate;
            vertical-align: top;
            border-spacing: 0 30px;

            tr {
                padding-bottom: 30px;
            }

            td {
                white-space: nowrap;
            }
        }
    }

    &__dropdown-name {
        font-size: 12px;
        line-height: 15px;
        padding-right: 30px;
    }
    &__dropdown-value {
    }

    &__dropdown-link {
        color: currentColor;
    }

    &__menu {
        margin-left: 20px;
        @include m-phone() {
        }
        &-wrap {
            @include m-phone() {
                height: 100%;
                overflow-y: scroll;
                padding-bottom: 60px;
                max-width: 345px;
                margin: 0 auto;
            }
        }
        &.active {
            @include m-phone() {
                display: block;
            }
        }
    }
}
.is-sticky,
.body_inner {
    .header {
        &_clear {
            background-color: #201f23;
        }
    }
}

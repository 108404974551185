.how {
    text-align: center;

    &__title {
        margin-bottom: 40px;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
        text-align: left;
        @include m-phone() {
            display: block;
        }
    }

    &__item {
        @include flex-item(4, 27px);
        margin-bottom: 0;
        position: relative;
        @include m-tablet() {
            @include flex-item(2, 27px);
        }
        @include m-phone() {
            margin-right: 0;
            max-width: 100%;
            width: 100%;
        }
    }
    &__item-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        padding-right: 20px;
    }
    &__item-icon {
        height: 60px;
        max-width: 100px;
    }
    &__item-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 57px;
        height: 22px;
        stroke: $color_black;
        stroke-width: 1px;
        @include m-phone() {
            transform: rotate(90deg);
        }
    }

    &__item-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        margin-bottom: 20px;
    }

    &__item-text {
        font-size: 14px;
        line-height: 170%;
    }
}

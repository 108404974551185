.contacts {
    &__container {
        display: flex;
        @include m-tablet() {
            display: block;
        }
    }

    &__info {
        padding: 100px 0;
        padding-right: 70px;
        width: 50%;
        @include m-tablet() {
            width: 100%;
            padding: 30px 0;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 30px;
        line-height: 120%;
        text-transform: uppercase;
        color: #2d3a5c;
        margin-bottom: 32px;
        @include m-phone() {
            font-size: 24px;
        }
    }

    &__text {
        font-size: 20px;
        line-height: 170%;
        margin-bottom: 10px;
        @include m-phone() {
            font-size: 16px;
        }
    }

    &__link {
        font-weight: bold;
        font-size: 40px;
        line-height: 120%;
        color: $color_main;
        display: inline-block;
        margin: 20px 0;
        @include m-phone() {
            font-size: 24px;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        @include m-phone() {
            display: block;
        }
    }

    &__social-item {
        display: flex;
        align-items: center;
        margin-right: 40px;
        @include m-phone() {
            margin-right: 0;
            margin-bottom: 20px;
        }
        &:hover {
            .contacts {
                &__social-icon {
                    fill: $color_main;
                    @include m-tablet() {
                        fill: #232e40;
                    }
                }
                &__social-text {
                    color: $color_main;
                    @include m-tablet() {
                        color: #232e40;
                    }
                }
            }
        }
        &:last-of-type {
            margin-right: 0;
        }
    }

    &__social-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        width: 19px;
        height: 16px;
        fill: #232e40;
        transition: $trsn;
    }

    &__social-text {
        flex: 0 1 100%;
        font-size: 16px;
        line-height: 170%;
        color: #575454;
        transition: $trsn;
    }

    &__form {
        padding: 100px 0;
        padding-left: 70px;
        width: 50%;
        position: relative;
        @include m-tablet() {
            width: 100%;
            padding: 30px 0;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100%;
            background: #f6f8fd;
            z-index: -1;
            @include m-tablet() {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &__form-fields {
        margin-top: 40px;
        margin-bottom: 30px;
        .form {
            &__field {
                margin-bottom: 40px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.icons {
  height: 0;
  margin-bottom: 100px;
  @include m-phone() {
    display: none;
  }
  .container {
    height: 0;
    display: flex;
    align-items: center;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $color_white;
    padding: 45px 30px 35px;
    @include m-phone() {
      display: block;
      padding: 20px 10px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    @include m-phone() {
      margin-bottom: 20px;
      &:last-of-type() {
        margin-bottom: 0;
      }
    }
  }

  &__item-icon {
    flex: 0 0 50px;
    height: 50px;
    margin-right: 30px;
  }

  &__item-text {
    font-size: 14px;
    line-height: 150%;
    color: #000;
    text-transform: uppercase;
  }
}

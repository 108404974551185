html {
  font-size: $font_size;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  height: 100%;
  @include m-phone() {
    font-size: $font_size_m;
  }
}

body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  color: $color_black;
  font-weight: $font_weight;
  line-height: $line_height;
  font-family: $font_base;
  // overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  background: $color_white;
  @include m-phone() {
    line-height: $line_height_m;
  }
  &.open {
    overflow: hidden;
  }
}

.closed {
  color: $color_main !important;
  text-transform: uppercase !important;
}

svg {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  &:focus,
  &.focus {
    outline: none;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.title1 {
  @include title(45px, 55px, 800);
  text-transform: uppercase;
  @include m-desktop-small() {
    @include title(35px, 45px, 800);
  }
  @include m-laptop() {
    @include title(32px, 42px, 800);
  }
  @include m-phone() {
    @include title(20px, 28px, 800);
  }
}
.title2 {
  @include title(40px, 52px, 700);
  color: $color_black;
  text-transform: uppercase;
  @include m-laptop() {
    @include title(32px, 42px, 800);
  }
  @include m-tablet() {
    @include title(28px, 34px, 800);
  }
  @include m-phone() {
    @include title(24px, 28px, 800);
  }
}
.title3 {
  @include title(30px, 39px, 700);
  color: $color_black;
  text-transform: uppercase;
}

a {
  outline: none;
  text-decoration: none;
  transition: $trsn;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }
}

.wrapper {
  width: 100%;
  flex: 1 0 auto;
  // overflow-x: hidden;
}
section {
  overflow-x: hidden;
}

.container {
  max-width: 1170px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  @include m-tablet-wide() {
    max-width: 1024px;
  }
  @include m-tablet() {
    max-width: 768px;
  }
  @include m-phone() {
    max-width: 425px;
    width: 100%;
  }
}

img {
  max-width: 100%;
  display: block;
}

.hidden {
  display: none !important;
}

[role="button"] {
  cursor: pointer;
  transition: $trsn;
}

.hidden_desktop {
  display: none !important;
  @include m-phone() {
    display: block !important;
  }
}
.hidden_mobile {
  @include m-phone() {
    display: none !important;
  }
}

.offices {
    overflow-x: hidden;
    &_dark {
        .offices {
            &__info {
                &::before {
                    background-color: #232e40;
                }
            }
        }
    }
    &__container {
    }

    &__heading {
        color: $color_white;
        margin-bottom: 40px;
    }

    &__info {
        padding: 30px 0 0;
        width: 786px;
        max-width: 100%;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100vw;
            height: 100%;
            background-color: $color_main;
            z-index: -1;
            @include m-tablet() {
                right: auto;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &__tabs {
        display: flex;
        margin-bottom: 30px;
        @include m-phone() {
            flex-wrap: wrap;
            margin-bottom: 10px;
        }
    }

    &__tabs-item {
        font-weight: 500;
        font-size: 14px;
        line-height: 1;
        padding: 20px;
        margin-right: 10px;
        color: $color_white;
        background-color: rgba(255, 255, 255, 0.2);
        white-space: nowrap;

        @include m-phone() {
            padding: 20px 10px;
            @include flex-item(2, 10px);
            font-size: 11px;
            text-align: center;
        }
        &.active {
            color: $color_black !important;
            background-color: rgba(255, 255, 255, 1) !important;
        }
        &:hover {
            color: $color_black;
            background-color: rgba(255, 255, 255, 1);
            @include m-tablet() {
                color: $color_white;
                background-color: rgba(255, 255, 255, 0.2);
            }
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__data {
        position: relative;
        width: 440px;
        padding-bottom: 108px;
        @include m-tablet() {
            width: 100%;
            padding-bottom: 30px;
        }
    }
    &__data-wrap {
        position: relative;
        z-index: 1;
        @include m-tablet() {
            margin-bottom: 30px;
        }
    }

    &__data-item {
        display: none;
        background: $color_white;
        box-shadow: 15px 0px 30px rgba(0, 0, 0, 0.25);
        &.active {
            display: block;
        }
    }

    &__title {
        background: #f6f8fd;
        padding: 30px 50px;
        @include m-phone() {
            padding: 20px;
            font-size: 18px;
        }
    }

    &__table {
        padding: 30px 50px;
        @include m-phone() {
            padding: 20px;
        }
        table {
            tr {
                &:last-of-type {
                    td {
                        padding-bottom: 0;
                    }
                }
                td {
                    vertical-align: top;
                    padding-bottom: 33px;
                    padding-right: 30px;
                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    &__name {
        padding-top: 6px;
        font-size: 14px;
        line-height: 17px;
    }

    &__value {
    }

    &__link {
        color: currentColor;
    }
    &__map {
        position: absolute;
        bottom: 0;
        left: 390px;
        width: 100vw;
        max-width: 1200px;
        height: 100%;
        z-index: 0;
        overflow: hidden;

        @include m-tablet() {
            position: relative;
            left: auto;
            top: auto;
            height: 400px;
            width: 100%;
        }
        #mapid {
            height: 100%;
        }

        .googlemap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.color-scale {
    &__container {
        text-align: center;
    }

    &__slider {
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        @include m-phone() {
            display: block;
        }
    }

    &__buttons {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include m-tablet() {
            top: 0;
            transform: none;
        }
        @include m-phone() {
            position: static;
            margin-bottom: 30px;
        }
    }

    &__btn {
        background-color: transparent;
        color: $color_main;
        &:hover {
            color: #a52222;
            @include m-tablet() {
                color: $color_main;
            }
            .btn {
                &__icon {
                    stroke: #a52222;
                    @include m-tablet() {
                        stroke: $color_main;
                    }
                }
            }
        }
        &:disabled {
            cursor: not-allowed;
            color: rgba(0, 0, 0, 0.5) !important;
            .btn {
                &__icon {
                    stroke: rgba(0, 0, 0, 0.5) !important;
                }
            }
        }
        &:first-of-type {
            .btn {
                &__icon {
                    margin-left: 0;
                    margin-right: 20px;
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .btn {
            &__icon {
                stroke: $color_main;
            }
        }
        &:hover {
            background-color: transparent;
        }
    }

    &__slider-wrap {
    }

    &__slider-item {
    }

    &__text {
        font-size: 25px;
        line-height: 130%;
        margin-bottom: 20px;
    }
    &__hex {
        font-size: 18px;
        line-height: 170%;
        margin-bottom: 26px;
    }

    &__colors {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 56px;
    }

    &__colors-item {
        cursor: pointer;
        position: relative;
        flex: 0 0 50px;
        height: 50px;
        width: 50px;
        margin: 0 5px;
        margin-bottom: 10px;
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            border: 2px solid $color_main;
            opacity: 0;
            transition: $trsn;
        }
        &:hover {
            &:after {
                opacity: 1;
            }
        }
        &.active {
            &:after {
                opacity: 1;
            }
        }
    }
}

.tech {
    background: #f6f8fd;
    padding: 0 !important;
    overflow-x: visible;
    &__wrap {
        display: flex;
        @include m-tablet() {
            display: block;
        }
    }
    &__container {
        padding: 100px 0;
        padding-right: 60px;
        width: 750px;
        position: relative;
        @include m-tablet() {
            width: 100%;
            padding: 30px 0;
        }
        &:before {
            content: "";
            width: 100vw;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            background-color: $color_white;
            @include m-tablet() {
                right: auto;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &__title {
        margin-bottom: 40px;
    }

    &__content {
        p {
            font-size: 16px;
            line-height: 170%;
            margin-bottom: 40px;
        }

        figure {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            @include m-phone() {
                display: block;
            }
            p {
                margin-bottom: 0;
            }
            figcaption {
                padding-left: 30px;
                @include m-phone() {
                    margin-top: 20px;
                    padding-left: 0;
                }
            }
        }

        ul {
            margin-bottom: 40px;
            li {
                position: relative;
                list-style-type: none;
                padding-left: 50px;
                margin-bottom: 30px;
                &::before {
                    content: "";
                    position: absolute;
                    top: 8px;
                    left: 20px;
                    width: 7px;
                    height: 7px;
                    background-color: $color_main;
                    border-radius: 50%;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
                p {
                    margin-bottom: 0;
                }
                ul {
                    margin-top: 20px;
                }
            }
        }
        ol {
            margin-bottom: 40px;
            padding-left: 40px;
            li {
                position: relative;
                padding-left: 10px;
                p {
                    margin-bottom: 0;
                }
            }
        }

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 30px;
            td,
            th {
                text-align: left;
                width: 50%;
                padding: 20px 15px;
            }
            th {
                font-size: 14px;
                line-height: 170%;
                color: $color_white;
                background: #232e40;
            }
            td {
                border-bottom: 1px solid #c5c6ca;
                &:nth-of-type(2n) {
                    border-left: 1px solid #c5c6ca;
                }
            }
        }
    }

    &__section {
        margin-bottom: 60px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__section-title {
        font-size: 30px;
        line-height: 130%;
        padding-bottom: 20px;
        border-bottom: 2px solid $color_main;
        margin-bottom: 20px;
        @include m-tablet() {
            font-size: 24px;
        }
    }

    &__section-subtitle {
        font-size: 22px;
        line-height: 130%;
        margin-bottom: 30px;
    }

    &__nav {
        padding-left: 70px;
        padding: 100px 0;
        @include m-tablet() {
            display: none;
        }
    }
    &__nav-wrap {
        position: sticky;
        top: 200px;
        padding-left: 70px;
    }

    &__nav-item {
        margin-bottom: 30px;
        position: relative;
        &:last-of-type {
            margin-bottom: 0;
        }
        &::before {
            content: "";
            position: absolute;
            top: 13px;
            left: -70px;
            width: 53px;
            height: 2px;
            background-color: $color_main;
            opacity: 0;
            transition: $trsn;
        }
        &:hover {
            &::before {
                opacity: 1;
            }
            .tech {
                &__nav-link {
                    color: $color_main;
                }
            }
        }
        &.active {
            &::before {
                opacity: 1;
            }
            .tech {
                &__nav-link {
                    color: $color_main;
                }
            }
        }
    }

    &__nav-link {
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        color: $color_black;
    }
}

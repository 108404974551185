$font_size: 18px;
$line_height: 170%;
$font_size_m: 14px;
$line_height_m: 22px;
$font_weight: 400;

$font_base: "Montserrat", sans-serif;

//цвета
$color_white: #ffffff;
$color_black: #000000;
$color_main: #cc2424;
//отступ
$padding: 0px;

//
$trsn: 0.2s;

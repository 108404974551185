.calculator {
    &__container {
    }

    &__title {
        font-size: 40px;
        line-height: 130%;
        margin-bottom: 20px;
        @include m-phone() {
            font-size: 24px;
        }
    }

    &__text {
        margin-bottom: 30px;
    }

    &__tabs {
        display: flex;
        margin-bottom: 50px;
    }
    &__tabs-item {
        cursor: pointer;
        padding: 20px;
        color: $color_black;
        background-color: #f6f8fd;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        transition: $trsn;
        &:hover {
            background-color: $color_main;
            color: $color_white;
            @include m-tablet() {
                color: $color_black;
                background-color: #f6f8fd;
            }
        }
        &.active {
            background-color: $color_main !important;
            color: $color_white !important;
        }
    }

    &__list {
    }
    &__list-item {
        display: none;
        &.active {
            display: block;
        }
    }

    &__form {
    }

    &__form-row {
        display: flex;
        margin-bottom: 40px;
        @include m-phone() {
            display: block;
        }
    }
    &__form-item {
        @include flex-item(3, 30px);
        @include m-phone() {
            width: 100%;
            max-width: 100%;
            margin-right: 0;
        }
    }

    &__select {
        position: relative;
    }

    &__select-span {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        position: absolute;
        top: 0;
        left: 0;
        padding: 11px 1px;
        opacity: 0;
        transition: 0.2s;
        z-index: 1;
        &.active {
            font-size: 12px;
            line-height: 15px;
            opacity: 1;
            transform: translateY(-25px);
        }
    }

    &__data {
        display: flex;
        align-items: center;
        @include m-phone() {
            display: block;
        }
        .btn {
            margin-right: 144px;
            @include m-phone() {
                margin-right: 0;
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }

    &__data-info {
        display: none;
        &.active {
            display: block;
        }
    }

    &__data-text {
        font-size: 30px;
        line-height: 130%;
        margin-bottom: 10px;
        @include m-phone() {
            font-size: 18px;
        }
    }

    &__data-span {
        font-size: 16px;
        line-height: 170%;
    }
}

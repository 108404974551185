.factory {
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include m-phone() {
            display: block;
        }
    }

    &__img {
        flex: 0 1 100%;
        position: relative;
        height: 650px;
        @include m-phone() {
            display: none;
        }
        img {
            position: absolute;
            top: 0;
            right: 0;
            width: 750px;
            height: 100%;
            object-fit: cover;
            max-width: none;
        }
    }

    &__info {
        flex: 0 0 700px;
        margin-left: 80px;
        @include m-tablet-wide() {
            flex: 0 0 500px;
        }
        @include m-tablet() {
            margin-left: 30px;
        }
        @include m-phone() {
            margin-left: 0;
        }
    }

    &__name {
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    &__title {
        margin-bottom: 30px;
    }

    &__p {
        margin-bottom: 40px;
    }
}

.roof {
    .owl-stage {
        display: flex;
    }
    &__item {
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @include m-phone() {
            width: 100%;
        }
    }
}

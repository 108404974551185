.delivery {
  &_gray {
    @include m-phone() {
      background: #f6f8fd;
    }
    .delivery {
      &__container {
        background: #f6f8fd;
        @include m-phone() {
          background-color: transparent;
        }
      }
    }
  }

  &__container {
    padding: 60px 200px;
    @include m-tablet() {
      padding: 60px 0;
    }
  }

  &__title {
    margin-bottom: 40px;
    @include m-tablet() {
    }
  }

  &__content {
    .know-more {
      display: inline-block;
      margin-top: 30px;
    }
  }

  &__item {
    margin-bottom: 60px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__item-title {
    font-size: 30px;
    line-height: 130%;
    padding-bottom: 20px;
    border-bottom: 2px solid $color_main;
    margin-bottom: 20px;
  }

  &__item-timetable {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    @include m-phone() {
      flex-wrap: wrap;
    }
  }

  &__item-items {
    display: flex;
  }
  &__item-info {
    margin-top: -2px;
    display: flex;
    align-items: center;
    margin-right: 18px;
    @include m-phone() {
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  &__item-span {
    font-weight: 600;
    font-size: 16px;
    line-height: 170%;
  }
  &__item-icon {
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    margin-right: 20px;
  }
  &__item-col {
    margin-right: 18px;
    &:last-of-type {
      margin-right: 0;
    }
  }

  &__item-city {
    font-weight: bold;
    font-size: 16px;
    line-height: 170%;
  }

  &__item-days {
  }

  &__item-day {
    font-size: 16px;
    line-height: 170%;
  }

  &__item-text {
    font-size: 16px;
    line-height: 170%;

    ul {
      margin-bottom: 40px;
      li {
        position: relative;
        list-style-type: none;
        padding-left: 50px;
        margin-bottom: 10px;
        &::before {
          content: "";
          position: absolute;
          top: 8px;
          left: 20px;
          width: 7px;
          height: 7px;
          background-color: $color_main;
          border-radius: 50%;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        p {
          margin-bottom: 0;
        }
        ul {
          margin-top: 20px;
        }
      }
    }
  }
  &__item-img {
    margin: 30px 0 40px;
  }
}

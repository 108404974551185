@mixin flex-item($items, $mr) {
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: #{$mr};
    margin-bottom: #{$mr};
    flex-basis: calc(100% / #{$items} - (#{$mr} * (#{$items} - 1)) / #{$items});
    max-width: calc(100% / #{$items} - (#{$mr} * (#{$items} - 1)) / #{$items});
    &:nth-child(#{$items + 1}n) {
        margin-right: #{$mr};
    }
    &:nth-child(#{$items}n) {
        margin-right: 0;
    }
}
@mixin title($fz, $lh, $fw) {
    font-size: #{$fz};
    line-height: #{$lh};
    font-weight: #{$fw};
    font-family: $font_base;
}

@mixin picture($padding-top, $object-fit) {
    padding-top: $padding-top * 1%;
    overflow: hidden;
    position: relative;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: $object-fit;
    }
}

@mixin m-desktop-small(){
    @media (max-width: 1650px) {
        @content;
    }
}
@mixin m-laptop(){
    @media (max-width: 1440px) {
        @content;
    }
}
@mixin m-tablet-wide(){
    @media (max-width: 1279px) {
        @content;
    }
}
@mixin m-tablet(){
    @media (max-width: 1023px) {
        @content;
    }
}
@mixin m-phone(){
    @media (max-width: 767px) {
        @content;
    }
}
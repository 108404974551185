.fence {
    background: #f6f8fd;
    &__title {
        margin-bottom: 40px;
    }

    &__container {
        display: flex;
        @include m-phone() {
            display: block;
        }
    }

    &__item {
        @include flex-item(2, 30px);
        background-color: $color_white;
        padding: 30px;
        padding-bottom: 0;
        transition: $trsn;
        @include m-phone() {
            margin-right: 0;
            max-width: 100%;
            width: 100%;
            padding: 20px 10px;
        }
        &:hover {
            .fence {
                &__item-title {
                    color: #cc2424;
                }
            }
            box-shadow: 0px 14px 19px rgba(204, 36, 36, 0.15);
        }
    }

    &__item-title {
        font-size: 24px;
        line-height: 130%;
        text-transform: uppercase;
        margin-bottom: 20px;
        transition: $trsn;

        @include m-tablet-wide() {
            font-size: 24px;
        }
    }
    &__item-text {
        font-size: 16px;
        line-height: 170%;
        margin-bottom: 30px;
    }

    &__item-table {
        margin-bottom: 20px;
    }

    &__item-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    &__item-name {
        display: flex;
        align-items: baseline;
        position: relative;
        flex: 0 1 100%;
        margin-right: 20px;
        white-space: nowrap;
        font-size: 14px;
        line-height: 170%;
        @include m-tablet() {
            margin-right: 5px;
        }
        &::after {
            content: "";
            flex: 0 1 100%;
            height: 1px;
            background: #dfe1f0;
            margin-left: 20px;
            @include m-tablet() {
                margin-left: 5px;
            }
        }
    }

    &__item-value {
        font-size: 14px;
        line-height: 170%;
        white-space: nowrap;
    }

    &__item-img {
        height: 200px;
        overflow: hidden;
        margin-bottom: 30px;
    }
}

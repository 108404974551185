.header {
    &__menu {
        .menu {
            display: none;
        }
        &.active {
            .menu {
                display: block;
            }
        }
    }
}
.substrate {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    &.active {
        display: block;
    }
}
.menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 840px;
    height: 100vh;
    @include m-tablet() {
        width: 100vw;
    }
    &.active {
        display: block;
    }
    &__wrap {
        display: none;
        background: $color_white;
        padding: 100px;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: auto;
        @include m-tablet() {
            padding: 70px 15px;
        }
        &.active {
            display: block;
        }
    }
    &__links {
        margin-bottom: 150px;
        @include m-tablet-wide() {
            margin-bottom: 50px;
        }
    }

    &__links-item {
        margin-bottom: 0;
        color: #404040;
        @include m-tablet-wide() {
            margin-bottom: 30px;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
        &:hover {
            .menu {
                &__link {
                    color: $color_main;
                    @include m-tablet() {
                        color: #404040;
                    }
                    &::before {
                        width: 34px;
                    }
                }
            }
        }
    }

    &__link {
        cursor: pointer;
        color: $color_black;
        display: none;
        align-items: center;
        font-weight: 600;
        font-size: 25px;
        line-height: 150%;
        text-transform: uppercase;
        color: currentColor;
        position: relative;
        @include m-tablet() {
            display: flex;
        }
        @include m-phone() {
            font-size: 16px;
        }
        a {
            color: inherit;
        }
        &::before {
            content: "";
            width: 0;
            height: 3px;
            background-color: $color_main;
            transition: $trsn;
            margin-right: 16px;
            @include m-tablet() {
                display: none;
            }
        }
    }

    &__dropdown {
    }

    &__dropdown-item {
        margin-bottom: 30px;
        @include m-tablet-wide() {
            margin-bottom: 10px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        @include m-tablet() {
            padding-left: 20px;
            &:first-of-type {
                margin-top: 10px;
            }
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__dropdown-link {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 25px;
        line-height: 150%;
        text-transform: uppercase;
        color: #404040;
        position: relative;
        @include m-phone() {
            font-size: 16px;
        }
        &:hover {
            color: $color_main;
            @include m-tablet() {
                color: #404040;
            }
            &::before {
                width: 34px;
            }
        }
        a {
            color: inherit;
        }
        &::before {
            content: "";
            width: 0;
            height: 3px;
            background-color: $color_main;
            transition: $trsn;
            margin-right: 16px;
            @include m-tablet() {
                display: none;
            }
        }
    }

    &__form {
        background: $color_white;
        padding: 100px;
        width: 100%;
        height: 100%;
        position: relative;
        display: none;
        flex-direction: column;
        justify-content: center;
        overflow: auto;

        &.active {
            display: flex;
        }
        @include m-tablet() {
            padding: 70px 15px;
            &.active {
                display: block;
            }
        }
    }

    &__form-title {
        margin-bottom: 30px;
    }

    &__form-text {
        margin-bottom: 50px;
    }

    &__form-fields {
        margin-bottom: 50px;

        .form__field {
            margin-bottom: 40px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.form {
    &__select {
        height: 47px;
    }
}
.jq-selectbox {
    font-family: $font_base;
    position: relative;
    z-index: 100;
    width: 100%;
    &.focused {
        .jq-selectbox {
            &__select {
                border: none;
                border-bottom: 2px solid rgba(0, 0, 0, 0.2);
            }
        }
    }
    &.opened {
        .jq-selectbox {
            &__trigger {
                transform: rotate(180deg);
            }
        }
    }
    &:hover {
        background: transparent;
    }
    &__select {
        height: 100%;
        padding: 11px 1px;
        border-radius: 0;
        border: none;
        background: transparent;
        border-bottom: 2px solid rgba(0, 0, 0, 0.2);
        color: $color_black;
        box-shadow: none;
        text-shadow: none;
        @include m-phone() {
            padding: 11px 1px;
        }
        &:hover {
            background: transparent;
        }
        &:active {
            background: #fcfcfc;
            box-shadow: none;
        }
        &-text {
            font-family: $font_base;
            font-weight: 400;
            height: 20px;
            font-size: 16px;
            line-height: 20px;
            @include m-phone() {
                font-size: 16px !important;
            }
            &.placeholder {
                color: $color_black;
            }
        }
    }

    &__trigger {
        border-left: none;
        transition: $trsn;
        &-arrow {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 17px;
            height: 10px;
            border: none;
            background: url("../img/svg/trigger.svg") center center no-repeat;
            background-size: 17px 10px;
        }
    }

    &__dropdown {
        border-radius: 0;
        margin: 0;
    }

    & li {
        font-family: $font_base;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $color_black;
        padding: 10px 12px;
        background: #f6f8fd;
        transition: $trsn;
        &.selected {
            color: $color_main;
        }
        &:hover {
            color: $color_main;
            background: #f6f8fd;

            @include m-tablet() {
                color: $color_black;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    select {
        font-size: 16px !important;
    }
}

.btn {
    cursor: pointer;
    border: none;
    display: inline-block;
    background-color: $color_main;
    color: $color_white;
    transition: $trsn;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;

    @include m-phone() {
        font-size: 14px;
    }
    &:hover {
        background-color: #a52222;
    }
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 21px 20px 21px 30px;
        @include m-phone() {
            padding: 20px 15px;
        }
    }

    &__text {
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 36px;
        width: 36px;
        height: 10px;
        margin-left: 20px;
        stroke: $color_white;
        stroke-width: 1.5;
    }
}

.nav {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: -20px;
    @include m-tablet() {
        display: none;
    }
    &.active {
        display: block;
    }
    &__item {
        margin: 0 25px;
        color: $color_white;
        height: 100%;
        transition: 0s;
        display: flex;
        align-items: center;
        justify-content: center;
        @include m-tablet-wide() {
            margin: 0 10px;
        }
        @include m-tablet() {
            padding: 0 15px;
        }
        @include m-phone() {
            display: block;
            width: 100%;
            color: $color_white;
            max-width: 375px;
            margin: 0 auto;
            height: initial;
            margin-top: 20px;
        }
        &_hasmenu {
            margin: 0;
            padding: 0 25px;
            transition: $trsn;
            @include m-tablet-wide() {
                padding: 0 10px;
            }
            &:hover {
                background-color: $color_white;
                @include m-tablet() {
                    background-color: transparent;
                }
                .nav {
                    &__link {
                        color: $color_black;
                    }
                    &__arrow {
                        stroke: $color_black;
                    }
                }
            }
            &:before {
                content: "";
                width: 100vw;
                height: 0;
                background-color: rgba(0, 0, 0, 0.3);
                position: fixed;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: -99;
                pointer-events: none;
                @include m-phone() {
                    display: none;
                }
            }
        }

        &:hover {
            @include m-phone() {
            }
            &:before {
                height: 100vh;
                opacity: 1;
                z-index: -1;
                @include m-phone() {
                    display: none;
                }
            }
            .nav {
                &__item-wrap {
                    &::before {
                        background-color: $color_white;
                    }
                }
                &__arrow {
                    transform: rotate(180deg);
                }
                &__submenu {
                    height: auto;
                }
            }
        }
    }

    &__item-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 5px;
            background-color: transparent;
            transition: $trsn background-color;
        }
    }
    &__link {
        font-size: 14px;
        font-weight: 600;
        color: $color_white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        transition: 0s;
        @include m-tablet() {
            font-size: 14px;
        }
        @include m-phone() {
            display: block;
            width: 100%;
            color: $color_white;
            margin: 5px 0px;
            font-size: 15px;
        }
    }
    &__arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 11px;
        height: 5px;
        margin-left: 17px;
        stroke: $color_white;
        stroke-width: 2px;
        transition: $trsn transform;
    }
    &__submenu {
        position: absolute;
        top: 100%;
        background-color: $color_white;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 1140px;
        transition: 0;
        height: 0;
        overflow: hidden;
        @include m-phone() {
            position: static;
            height: auto;
            background-color: $color_black;
        }
    }
    &__submenu-wrap {
        padding: 18px 10px;
        width: 100%;
        position: relative;
        z-index: 3;
        @include m-phone() {
            padding: 0;
        }
    }
    &__submenu-items {
        width: 300px;
        @include m-phone() {
            width: 100%;
        }
    }
    &__submenu-item {
        &.active {
            .nav__submenu-link {
                color: red;
                @include m-phone() {
                    color: $color_white;
                }
            }
            .nav__submenu-item-content {
                opacity: 1;
                z-index: 2;
            }
        }

        @include m-phone() {
            position: relative;
            padding-left: 10px;
            margin: 5px 0;
            &:before {
                content: "";
                width: 12px;
                height: 2px;
                background-color: red;
                position: absolute;
                left: 0;
                top: 11px;
            }
        }
    }
    &__submenu-link {
        display: block;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        padding: 13px 20px;
        color: $color_black;
        @include m-phone() {
            padding: 5px 0 5px 15px;
            font-size: 13px;
            color: $color_white;
        }
    }
    &__submenu-item-content {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% - 310px);
        padding: 30px 50px 30px 80px;
        display: flex;
        height: 100%;
        align-items: center;
        opacity: 0;
        z-index: -1;

        @include m-tablet() {
            padding: 40px;
        }
        @include m-phone() {
            display: none;
        }
    }
    &__submenu-item-prof {
        height: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    &__submenu-item-prof-link {
        @include flex-item(4, 20px);
        position: relative;

        &:hover {
            opacity: 0.8;
            @include m-tablet() {
                opacity: 1;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__submenu-item-prof-span {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        padding: 0 10px;
        transform: translateY(-50%);
        text-align: center;
        font-weight: bold;
        font-size: 25px;
        line-height: 150%;
        color: $color_white;
        text-transform: uppercase;
    }
    &__submenu-item-img {
        padding: 10px;
        flex: 0 0 360px;
        width: 360px;
        height: 308px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background: #f6f8fd;
        @include m-tablet-wide() {
            flex: 0 0 260px;
            width: 260px;
        }
        @include m-tablet() {
            display: none;
        }
    }
    &__submenu-item-desc {
        flex: 0 0 50%;
        padding-left: 50px;
        @include m-tablet() {
            flex: 0 0 100%;
            padding-left: 0;
        }
    }
    &__submenu-item-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 130%;
        text-transform: uppercase;
        color: $color_black;
        padding-bottom: 30px;
        position: relative;
        &:before {
            content: "";
            width: 80%;
            height: 2px;
            background-color: red;
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translateX(-50%);
            @include m-tablet() {
                width: 200px;
                transform: translateX(0);
            }
        }
    }
    &__submenu-item-text {
        font-size: 16px;
        line-height: 1.7;
        color: #575454;
        margin-top: 30px;
    }
}
.is-sticky {
    .nav__item_hasmenu:before,
    .nav__item_hassmallmenu:before {
        background-color: rgba(0, 0, 0, 0.7);
    }
}

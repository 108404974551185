.selective {
    background: #f6f8fd;
    &__title {
        margin-bottom: 40px;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        @include m-phone() {
            display: block;
        }
    }

    &__item {
        cursor: pointer;
        position: relative;
        @include flex-item(3, 30px);
        @include m-tablet() {
            @include flex-item(2, 30px);
        }
        @include m-phone() {
            max-width: 100%;
            width: 100%;
            margin-right: 0;
        }
        &:first-of-type {
            .dop {
                z-index: 2;
            }
        }
        &_big {
            flex: 0 0 100%;
            max-width: 100%;
            margin-right: 0 !important;
            .selective {
                &__item {
                    &-wrap {
                        &:after {
                            display: none;
                        }
                    }
                    &-title {
                        margin-bottom: 0;
                        color: $color_main;
                    }
                }
            }
        }
        &:nth-child(3n - 1) {
            .selective {
                &__dropdown {
                    margin-left: calc(-100% - 30px);
                    @include m-tablet() {
                        margin-left: 0;
                    }
                }
            }
        }
        &:nth-child(3n) {
            .selective {
                &__dropdown {
                    margin-left: calc(-200% - 60px);
                    @include m-tablet() {
                        margin-left: 0;
                    }
                }
            }
        }
        &:nth-child(2n) {
            .selective {
                &__dropdown {
                    @include m-tablet() {
                        margin-left: calc(-100% - 30px);
                    }
                    @include m-phone() {
                        margin-left: 0;
                    }
                }
            }
        }

        &.active {
            .selective {
                &__dropdown {
                    display: flex;
                    @include m-phone() {
                        display: block;
                    }
                }
                &__item-wrap {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

        &:hover {
            .selective {
                &__item-wrap {
                    box-shadow: 0px 14px 19px rgba(204, 36, 36, 0.15);
                    @include m-tablet() {
                        box-shadow: none;
                    }
                }
                &__item-title {
                    color: $color_main;
                    @include m-tablet() {
                        color: $color_black;
                    }
                }
            }
        }
    }

    &__item-wrap {
        text-align: center;
        padding: 30px;
        background: $color_white;
        transition: $trsn;
        position: relative;
        &::after {
            content: "";
            width: 30px;
            height: 30px;
            background-color: $color_white;
            position: absolute;
            bottom: -50px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            opacity: 0;
            transition: $trsn;
        }
    }

    &__item-title {
        font-size: 24px;
        line-height: 130%;
        margin-bottom: 40px;
        transition: $trsn;
        @include m-tablet() {
            font-size: 18px;
        }
    }

    &__item-img {
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;

        img {
            position: relative;
            z-index: 1;
        }
        .dop {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }
    }

    &__dropdown {
        z-index: 2;
        position: relative;
        margin-top: 30px;
        display: none;
        align-items: center;
        width: calc(300% + 60px);
        padding: 40px;
        background: $color_white;
        box-shadow: 0px 14px 19px rgba(204, 36, 36, 0.15);
        @include m-tablet() {
            width: calc(200% + 30px);
        }
        @include m-phone() {
            width: 100%;
            padding: 20px;
        }
    }

    &__dropdown-info {
        margin-right: 120px;
        @include m-phone() {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    &__dropdown-text {
        white-space: nowrap;
        font-size: 25px;
        line-height: 130%;
        margin-bottom: 20px;
    }

    &__dropdown-hex {
        white-space: nowrap;
    }

    &__dropdown-colors {
        flex: 0 0 530px;
        display: flex;
        flex-wrap: wrap;
        @include m-tablet() {
            flex: 0 1 100%;
        }
    }

    &__dropdown-item {
        cursor: pointer;
        @include flex-item(9, 10px);
        position: relative;
        @include m-tablet() {
            margin-right: 0;
            &:nth-of-type(9n) {
                margin-right: 10px;
            }
            &:nth-of-type(6n) {
                margin-right: 0;
            }
            @include flex-item(6, 10px);
        }
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            border: 2px solid #cc2424;
            opacity: 0;
            z-index: -1;
        }
        &:hover {
            &:after {
                opacity: 1;
                @include m-tablet() {
                    opacity: 0;
                }
            }
        }
        &.active {
            &:after {
                opacity: 1 !important;
            }
        }
    }
}

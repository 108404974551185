.menu-btn {
    cursor: pointer;
    padding: 10px;
    margin-right: -10px;
    position: relative;
    z-index: 2;
    @include m-phone() {
        display: block;
    }
    &__wrap {
        width: 30px;
        height: 26px;
        position: relative;
        span {
            width: 100%;
            height: 2px;
            background-color: $color_white;
            top: 50%;
            margin-top: -1px;
            position: absolute;
            transition: opacity $trsn, transform $trsn, background-color $trsn;
            &:nth-child(1) {
                transform: translateY(-7px);
            }
            &:nth-child(2) {
                // transform: translateY(-6px);
            }
            &:nth-child(3) {
                transform: translateY(7px);
            }
        }
    }
    &.active {
        span {
            background-color: $color_main;

            &:nth-child(1) {
                transform: translateY(0) rotate(45deg);
            }
            &:nth-child(2) {
                transform: translateY(0) rotate(-45deg);
            }
            &:nth-child(3) {
                transform: translateY(0) translateX(-80px);
                opacity: 0;
            }
        }
    }
}

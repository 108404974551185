.descr {
    background: linear-gradient(180deg, #ffffff 0%, #f6f8fd 19.19%), #f6f8fd;
    &__row {
        display: flex;
        margin-bottom: 40px;
        @include m-tablet() {
            display: block;
        }
    }
    &__content {
        padding-bottom: 100px;
        @include m-phone() {
            padding-bottom: 30px;
        }
    }

    &__item {
        display: flex;
        border-bottom: 1px solid #cccccc;
        margin-bottom: 50px;
        @include m-tablet() {
            display: block;
        }
        &:last-of-type {
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    &__col {
        @include flex-item(2, 90px);
        @include m-tablet() {
            margin-right: 0;
            max-width: 100%;
            margin-bottom: 20px;
        }
    }

    &__title {
        @include flex-item(2, 90px);
        margin-bottom: 0;
        font-size: 40px;
        line-height: 130%;
        @include m-tablet() {
            font-size: 26px;
            margin-bottom: 10px;
            margin-right: 0;
            max-width: 100%;
            &:last-of-type {
                display: none;
            }
        }
        @include m-phone() {
            font-size: 20px;
            margin-bottom: 15px;
        }
    }

    &__subtitle {
        font-size: 25px;
        line-height: 130%;
        margin-bottom: 20px;
    }

    &__text {
        font-size: 16px;
        line-height: 170%;

        ul {
            padding-left: 20px;
        }
        p {
            margin-bottom: 10px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__img {
        margin-bottom: 30px;
    }
}

.main {
    display: flex;
    justify-content: space-between;
    @include m-tablet-wide() {
        display: block;
    }
    &__item {
        display: flex;
        align-items: flex-end;
        position: relative;
        color: $color_white;
        @include flex-item(3, 0px);
        padding: 40px 60px;
        height: 650px;
        @include m-desktop-small() {
            padding: 40px 30px;
        }
        @include m-tablet-wide() {
            max-width: 100%;
            width: 100%;
            height: auto;
        }
        @include m-phone() {
            padding: 20px;
        }
        &:hover {
            .main {
                &__img {
                    &::after {
                        opacity: 1;
                        @include m-tablet() {
                            opacity: 0;
                        }
                    }
                }
                &__p {
                    max-height: 0;
                    @include m-tablet-wide() {
                        max-height: none;
                    }
                }
            }
            .btn {
                max-height: 100px;
            }
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(78, 1, 1, 0.5), rgba(78, 1, 1, 0.5));
            opacity: 0;
            transition: $trsn;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__info {
        position: relative;
    }

    &__title {
        margin-bottom: 20px;
    }

    &__p {
        overflow: hidden;
        transition: $trsn;
        @include m-tablet-wide() {
            margin-bottom: 30px;
        }
    }
    .btn {
        max-height: 0;
        overflow: hidden;
        @include m-tablet-wide() {
            max-height: none;
        }
        @include m-phone() {
            width: 100%;
        }
    }
}

.technical {
    &__container {
        background: #f6f8fd;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 50px 160px 50px 50px;
        @include m-tablet() {
            padding: 30px 120px 30px 20px;
        }
        @include m-phone() {
            display: block;
            text-align: center;
            padding: 30px 10px;
        }
    }

    &__icon {
        flex: 0 0 109px;
        width: 109px;
        height: 90px;
        margin-right: 40px;
        @include m-tablet() {
            margin-right: 20px;
            flex: 0 0 70px;
            width: 70px;
            height: 60px;
        }
        @include m-phone() {
            margin: 0 auto 10px;
        }
    }

    &__text {
        font-size: 30px;
        line-height: 130%;
        margin-right: auto;
        margin-left: 0;
        @include m-tablet() {
            font-size: 20px;
        }
        @include m-phone() {
            margin-bottom: 30px;
        }
    }
}

@import "modules/footer/footer";
@import "modules/header/header";
@import "modules/nav/nav";
@import "modules/menu-btn/menu-btn";
@import "modules/menu/menu";
@import "modules/owl/owl";
@import "modules/btn/btn";
@import "modules/form/form";
@import "modules/section/section";
@import "modules/main/main";
@import "modules/prices/prices";
@import "modules/offices/offices";
@import "modules/factory/factory";
@import "modules/know-more/know-more";
@import "modules/technical/technical";
@import "modules/roof/roof";
@import "modules/metal-tile/metal-tile";
@import "modules/descr/descr";
@import "modules/icons/icons";
@import "modules/corrugated/corrugated";
@import "modules/selective/selective";
@import "modules/how/how";
@import "modules/delivery/delivery";
@import "modules/calculator/calculator";
@import "modules/select/select";
@import "modules/color-scale/color-scale";
@import "modules/contacts/contacts";
@import "modules/tech/tech";
@import "modules/fence/fence";
@import "modules/color-scheme/color-scheme";
@import "modules/garanties/garanties";

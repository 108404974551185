.form {
    &__field {
        display: block;
        width: 100%;
        border: none;
        position: relative;
        border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    }
    &__field-span {
        pointer-events: none;
        font-weight: 300;
        font-size: 16px;
        line-height: 27px;
        color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        transition: $trsn;
        &.active {
            font-size: 12px;
            line-height: 20px;
            opacity: 1;
            transform: translateY(-200%);
        }
    }
    &__input {
        font-size: 16px;
        padding: 12px 0;
        width: 100%;
        background-color: transparent;
        border: none;
        color: $color_black;
    }
    &__checkbox {
        display: inline-block;
        position: relative;
        -webkit-appearance: none;

        padding-left: 30px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        user-select: none;
        margin-top: 30px;
        margin-bottom: 40px;

        @include m-phone() {
            margin-bottom: 20px;
            margin-top: 10px;
        }
        &:hover {
            .form {
                &__checkbox-input {
                    & ~ .form {
                        &__checkbox-checkmark {
                            background-color: #ccc;
                            @include m-tablet() {
                                background-color: #eee;
                            }
                        }
                    }
                }
            }
        }
        .form {
            &__checkbox-input {
                &:checked ~ .form {
                    &__checkbox-checkmark {
                        background-color: $color_main !important;
                        &:after {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    &__checkbox-input {
        display: none;
    }

    &__checkbox-text {
        display: block;
        font-size: 14px;
        line-height: 22px;
        color: #575454;
    }

    &__checkbox-link {
        color: currentColor;
        border-bottom: 1px solid currentColor;
        &:hover {
            border-bottom: 1px solid transparent;
            @include m-tablet() {
                border-bottom: 1px solid currentColor;
            }
        }
    }

    &__checkbox-checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 16px;
        width: 16px;
        border-radius: 4px;
        background-color: #eee;
        transition: $trsn;
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 4px;
            top: 0px;
            width: 6px;
            height: 9px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
}

.owl {
    &-nav {
        display: flex;
        width: 1170px;
        padding: 0 15px;
        max-width: 100%;
        margin: 0 auto;
        transform: translateY(-50%);
    }
    &-prev {
        width: 105px;
        height: 60px;
        background-color: $color_main !important;
        transition: $trsn;
        @include m-tablet() {
            width: 50px;
            height: 40px;
            svg {
                width: 30px;
                margin: 0 10px;
            }
        }
        &:hover {
            background-color: #a52222 !important;
            @include m-tablet() {
                background-color: $color_main !important;
            }
        }
    }
    &-next {
        width: 105px;
        height: 60px;
        background-color: $color_main !important;
        transform: scale(-1, 1);
        transition: $trsn;
        @include m-tablet() {
            width: 50px;
            height: 40px;
            svg {
                width: 30px;
                margin: 0 10px;
            }
        }
        &:hover {
            background-color: #a52222 !important;
            @include m-tablet() {
                background-color: $color_main !important;
            }
        }
    }
}

.metal-tile {
    &__row {
        display: flex;
        @include m-tablet() {
            display: block;
        }
    }
    &__content {
        flex: 0 0 480px;
        padding-right: 90px;
        @include m-tablet() {
            padding-right: 0;
            margin-bottom: 30px;
        }
    }
    &__title {
        margin-bottom: 30px;
    }
    &__text {
        font-size: 18px;
        line-height: 170%;
        margin-bottom: 40px;
    }
    &__subtitle {
        font-size: 26px;
        line-height: 130%;
        margin-bottom: 25px;
        @include m-phone() {
            font-size: 20px;
            margin-bottom: 15px;
        }
    }
    &__table {
    }

    &__table-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    &__table-name {
        display: flex;
        align-items: baseline;
        position: relative;
        flex: 0 1 100%;
        margin-right: 20px;
        white-space: nowrap;
        font-size: 14px;
        line-height: 170%;
        @include m-tablet() {
            margin-right: 5px;
        }
        &::after {
            content: "";
            flex: 0 1 100%;
            height: 1px;
            background: #dfe1f0;
            margin-left: 20px;
            @include m-tablet() {
                margin-left: 5px;
            }
        }
    }

    &__table-value {
        font-size: 14px;
        line-height: 170%;
        white-space: nowrap;
    }

    &__slider {
        flex: 1 1 100%;
        min-width: 0;
        position: relative;
        @include m-phone() {
            margin-top: 30px;
            margin-left: -15px;
            margin-right: -15px;
        }
        &:before {
            content: "";
            width: 100%;
            height: 100px;
            background: #fff;
            position: absolute;
            top: 0;
            left: 100%;
            z-index: 3;
        }
        &:after {
            content: "";
            height: 100%;
            width: 80px;
            background: #fff;
            position: absolute;
            top: 0;
            left: 100%;
            z-index: 3;
        }
        &-wrap {
            width: 1900px;
            @include m-phone() {
                width: 100%;
            }
        }
        &-item {
            width: 660px;
            position: relative;
            @include picture(106.38, cover);
            transition: $trsn padding-top;
            @include m-tablet-wide() {
                width: 514px;
            }
            @include m-tablet() {
                width: 400px;
            }
            @include m-phone() {
                width: 100%;
                padding-top: 0;
            }
            img {
                top: inherit;
                bottom: 0;
                transition: $trsn;
                @include m-phone() {
                    height: 300px;
                    object-fit: cover;
                    position: static;
                    // top: auto;
                    // left: 0;
                }
            }
        }
    }
    .owl {
        &-item {
            overflow: hidden;
        }
        &-nav {
            bottom: 50px;
            left: 480px;
            @include m-tablet-wide() {
                left: 400px;
            }
            @include m-tablet() {
                left: 260px;
                bottom: 25px;
            }
            @include m-phone() {
                left: auto;
                right: 25px;
            }
        }
        &-prev {
            display: none;
        }
    }
    &__contact {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 30px;
        &-link {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #575454;
            font-size: 16px;
            line-height: 170%;
            span {
                margin-right: 15px;
            }
            img {
                max-width: 20px;
            }
            &:hover {
                color: red;
            }
        }
    }
}

.footer {
  background: #232e40;
  padding: 50px 0;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    @include m-phone() {
      display: block;
      text-align: center;
    }
  }

  &__logo {
    flex: 0 0 260px;
    margin-right: 130px;
    @include m-tablet-wide() {
      flex: 0 0 240px;
      margin-right: 10px;
    }
    @include m-phone() {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    flex: 0 1 100%;
    @include m-phone() {
      display: block;
      text-align: left;
      margin-bottom: 20px;
    }
  }

  &__col {
    &:first-of-type {
      margin-right: 70px;
      @include m-tablet() {
        margin-right: 0;
      }
      .footer {
        &__links {
          display: flex;
          flex-wrap: wrap;
          @include m-tablet() {
            display: block;
          }
        }
        &__links-item {
          @include flex-item(2, 30px);
          margin-bottom: 10px;
          @include m-tablet() {
            margin-right: 0;
            max-width: 100%;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    height: 27px;
    line-height: 1;
    color: $color_white;
    margin-bottom: 15px;
  }

  &__links {
  }

  &__links-item {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__link {
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    color: $color_white;
    border-bottom: 1px solid transparent;
    &::first-letter {
      text-transform: uppercase;
    }
    &:hover {
      border-bottom: 1px solid $color_white;
      @include m-tablet() {
        border-bottom: 1px solid transparent;
      }
    }
  }

  &__so {
    margin-top: 20px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.1);
    transition: $trsn;
    @include m-phone() {
      display: block;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.3);
      @include m-tablet() {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &__so-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 45px;
  }

  &__so-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 10px;
    width: 10px;
    height: 20px;
    margin-right: 20px;
  }
  &__so-text {
    font-size: 14px;
    line-height: 1;
    color: #ffffff;
  }

  &__copy {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    @include m-phone() {
      position: static;
    }
  }
}

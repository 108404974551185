.know-more {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: $color_main;
    position: relative;
    &:hover {
        &::after {
            left: 0;
            top: auto;
            bottom: -10px;
            width: 100%;
            transform: none;
        }
    }
    &::after {
        content: "";
        position: absolute;
        left: calc(100% + 20px);
        bottom: 50%;
        transform: translateY(-50%);
        width: 70px;
        height: 2px;
        background-color: $color_main;
        transition: $trsn;
        pointer-events: none;
        @include m-phone() {
            left: 0;
            top: auto;
            bottom: -10px;
            width: 100%;
            transform: none;
        }
    }
}

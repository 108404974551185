.color-scheme {
    &__container {
    }

    &__title {
        font-size: 40px;
        line-height: 130%;
        margin-bottom: 60px;
        text-align: center;
        @include m-phone() {
            font-size: 20px;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 20px;
    }

    &__item {
        width: 450px;
        text-align: center;
        @include m-tablet() {
            width: 300px;
        }
        @include m-phone() {
            width: 130px;
        }
    }

    &__item-img {
        margin-bottom: 20px;
    }

    &__item-text {
        font-size: 20px;
        line-height: 130%;
        @include m-phone() {
            font-size: 16px;
        }
    }

    &__subtitle {
        font-size: 25px;
        line-height: 130%;
        margin-bottom: 20px;
        text-align: center;
    }

    &__hex {
        font-size: 18px;
        line-height: 170%;
        text-align: center;
        margin-bottom: 30px;
    }

    &__colors {
        width: 100%;
        max-width: 590px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
    }

    &__colors-item {
        cursor: pointer;
        position: relative;
        @include flex-item(10, 10px);
        height: 50px;
        @include m-phone() {
            @include flex-item(5, 10px);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            border: 2px solid $color_main;
            opacity: 0;
            transition: $trsn;
        }
        &:hover {
            &:after {
                opacity: 1;
            }
        }
        &.active {
            &:after {
                opacity: 1;
            }
        }
    }
}

.prices {
    &__container {
        display: flex;
        @include m-tablet() {
            display: block;
        }
    }

    &__info {
        flex: 0 0 440px;
        margin-right: 80px;
        @include m-tablet() {
            margin-right: 0;
            margin-bottom: 30px;
        }
    }

    &__title {
        margin-bottom: 30px;
    }

    &__p {
    }

    &__form {
        flex: 0 1 100%;
        .btn {
            width: 100%;
        }
        .form__field {
            margin-bottom: 40px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &__form-fields {
        margin-bottom: 30px;
    }
}
